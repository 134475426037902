<template>
  <div v-if="loading">loading...</div>
  <div v-else-if="connection">
    <Title :pages="pages">
      <Button as="router-link" :to="editRoute"> Edit </Button>
    </Title>

    <div class="py-3 px-4 sm:px-6 lg:px-8">
      <Card>
        <CardItem heading="Name" :message="connection.name" />
        <CardItem heading="Adapter" :message="connection.adapter?.name" />
        <CardItem heading="Type" :message="connection.type?.name" />
        <CardItem
          v-for="value in values"
          :key="value.name"
          :heading="value.name"
          :message="value.value"
        />
      </Card>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, inject, ref } from "vue";
import { useRoute } from "vue-router";
import card from "@/components/common/card";
import Button from "@/components/common/Button";
import Title from "@/components/common/Title";

export default defineComponent({
  mixins: [card],
  components: {
    Title,
    Button,
  },
  setup() {
    const { params } = useRoute();
    const loading = ref(true);
    const connection = ref(null);
    const $axios = inject("axios");

    const values = computed(() => {
      if (!connection.value) {
        return [];
      }

      return Object.keys(connection.value.values).map((name) => {
        return {
          name,
          value: connection.value.values[name],
        };
      });
    });

    const editRoute = computed(() => {
      return {
        name: "connections.edit",
        params: { connection: connection.value?._id },
      };
    });

    const pages = computed(() => {
      return [
        { name: "Connections", href: "connections.index", current: false },
        {
          name: connection.value ? connection.value.name : "View",
          href: "connections.view",
          current: true,
        },
      ];
    });

    async function loadConnection() {
      loading.value = true;

      try {
        const { data: item } = await $axios.get(
          `/connections/${params.connection}`
        );

        connection.value = item.data;
      } catch (error) {
        //
      }

      loading.value = false;
    }

    loadConnection();

    return {
      pages,
      values,
      loading,
      editRoute,
      connection,
    };
  },
});
</script>
